<template>
  <div class="copyRight">
    <Top></Top>
    <div style="background-color: rgba(246, 246, 248, 1)">
      <div class="main">
        <div class="main_top">
          <div class="tab">
            <div
              class="ordinary"
              :class="goldId == item.id ? 'act-ordinary' : 'ordinary'"
              v-for="item in types"
              :key="item.id"
              @click="checkTab(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div v-if="goldId == 1 ? true : false">
          <div class="box">
            <van-tabs
              @change="changes_isjoin"
              v-model:active="activename"
              animated
              background="transparent"
              line-width="0.20rem"
              title-active-color="rgba(237, 147, 30, 1)"
              title-inactive-color="rgba(133, 140, 159, 1)"
            >
              <van-tab
                class="procecing"
                :title="lang.v.title1"
                name="1"
                title-style="font-size: 0.18rem; font-family: PingFang SC, PingFang SC; font-weight: 500;"
              >
              </van-tab>
              <van-tab
                class="selected"
                :title="lang.v.title2"
                name="2"
                title-style="font-size: 18rem; font-family: PingFang SC, PingFang SC; font-weight: 500;"
              >
              </van-tab>
            </van-tabs>
          </div>
          <div class="main_body">
            <div v-for="role in projects.list" :key="role.id">
              <div
                class="project"
                :class="activename == 1 ? 'project' : 'project_no'"
              >
                <div class="project_top">
                  <div class="image-wrapper">
                    <div class="image">
                      <img :src="role.content" alt="" />
                    </div>
                  </div>
                </div>
                <div
                  style="display: flex; justify-content: space-between"
                  class="name_time"
                >
                  <div class="project_name" style="margin-left: 0.14rem">
                    {{ role.name }}
                  </div>
                  <div class="project_time" style="margin-right: 0.14rem">
                    {{ updateCountdown(role.endTime) }}
                  </div>
                </div>
                <div class="project_info">
                  <table>
                    <tr class="first_info">
                      <td>{{ role.income * 365 }}%</td>
                      <td>{{ role.userMinAmount }}</td>
                      <td>
                        <p class="text">
                          {{ role.progressMoney }}/{{ role.totalMoney }}
                        </p>
                      </td>
                    </tr>
                    <tr class="second_info">
                      <td>{{ lang.v.annualized }}</td>
                      <td>{{ lang.v.Initial }}</td>
                      <td>{{ lang.v.Completed }}</td>
                    </tr>
                  </table>
                </div>
                <div class="tip">
                  {{ lang.v.tishi }}
                </div>
                <div class="project_foot">
                  <div class="project_schedule">
                    <div class="schedule" style="width: 100%">
                      <van-progress
                        :percentage="role.progress"
                        :show-pivot="false"
                        stroke-width="0.09rem"
                      />
                    </div>
                    <div class="src_schedule">
                      {{ lang.v.Crowdfunding }} {{ role.progress }}%
                    </div>
                  </div>
                  <div @click="isshow(role)" class="project_check">
                    {{ lang.v.buynow }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="goldId == 1 ? false : true">
          <div class="fenhong">
            <div class="fenhong_top">
              <div class="title_2">{{ lang.v.dividend }}</div>
              <div class="fenhong_nav">
                <div class="left_fenhong_nav">
                  <span
                    style="
                      height: 0.21rem;
                      font-size: 0.16rem;
                      font-family: MiSans, MiSans;
                      font-weight: 600;
                      color: #444444;
                      line-height: 0.19rem;
                    "
                  >{{ lang.v.unit }}</span
                  >
                  <span
                    style="
                      height: 0.42rem;
                      font-size: 0.32rem;
                      font-family: MiSans, MiSans;
                      font-weight: 600;
                      color: #444444;
                      line-height: 0.38rem;
                    "
                  >{{ userinfo.investmentBalance }}</span
                  >
                </div>
                <div
                  class="right_fenhong_nav"
                  @click="Onwithdrawal"
                  v-if="!drawing"
                >
                  {{ lang.v.wallet }}
                </div>
                <div class="right_fenhong_nav" v-else>
                  <van-loading type="spinner" size="0.24rem" color="#FFFFFF" />
                </div>
              </div>
            </div>
            <div class="fenhong_bottom">
              {{ lang.v.Cumulative }} ${{ userinfo.investmentIncome }}
            </div>
          </div>
          <div class="box">
            <van-tabs
              @change="changes_isjoin"
              v-model:active="activename_mine"
              animated
              background="transparent"
              line-width="0.20rem"
              title-active-color="rgba(237, 147, 30, 1)"
              title-inactive-color="rgba(133, 140, 159, 1)"
            >
              <van-tab
                class="procecing"
                :title="lang.v.title3"
                name="1"
                title-style="font-size: 0.18rem; font-family: PingFang SC, PingFang SC; font-weight: 500;"
              >
              </van-tab>
              <van-tab
                class="selected"
                :title="lang.v.title4"
                name="2"
                title-style="font-size: 18rem; font-family: PingFang SC, PingFang SC; font-weight: 500;"
              >
              </van-tab>
            </van-tabs>
          </div>
          <div class="main_body">
            <div v-for="role in projects.list" :key="role.id">
              <div
                class="project_fenhong"
                :class="activename == 1 ? 'project' : 'project_no'"
              >
                <div class="fenhong_top_2">
                  <div class="title_2">
                    <div>{{ lang.v.Project }}</div>
                    <div
                      :innerText="
                        activename_mine == 1 ? lang.v.text5 : lang.v.text6
                      "
                    ></div>
                  </div>
                  <div class="fenhong_nav">
                    <div class="fenhong_name">{{ role.name }}</div>
                    <div
                      class="fenhong_time"
                      :innerHTML="
                        activename_mine == 1 ? role.buyTime : role.getIncomeTime
                      "
                    ></div>
                  </div>
                </div>
                <div class="fenhong_bottom">
                  <div class="fenhong_box">
                    <div class="box_top">
                      <div class="stick"></div>
                      <div
                        class="title"
                        :innerText="
                          activename_mine == 1 ? lang.v.text7 : lang.v.text8
                        "
                      ></div>
                    </div>
                    <div
                      class="box_nav"
                      :innerHTML="
                        activename_mine == 1
                          ? role.totalIncome
                          : role.incomeMoney
                      "
                    ></div>
                  </div>
                  <div
                    class="fenhong_box"
                    v-if="activename_mine == 1 ? false : true"
                  >
                    <div class="box_top">
                      <div class="stick3"></div>
                      <div class="title">{{ lang.v.Dividend }}</div>
                    </div>
                    <div class="box_nav">{{ role.income }}%</div>
                  </div>
                  <div class="fenhong_box">
                    <div class="box_top">
                      <div class="stick2"></div>
                      <div class="title">{{ lang.v.investment }}</div>
                    </div>
                    <div class="box_nav">{{ role.buyMoney }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <van-loading size="0.24rem" v-if="loading">{{ lang.v.load }}</van-loading>
      <div
        v-if="more"
        style="display: flex; justify-content: center; margin-top: 0.14rem"
        @click="loadmore"
      >
        <div
          style="
            height: 0.24rem;
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #ed931e;
            line-height: 0.24rem;
          "
        >
          {{ lang.v.loadmore }}
        </div>
        <div style="width: 0.24rem; height: 0.24rem">
          <img width="100%" src="~@/assets/img/down_senior.png" alt="" />
        </div>
      </div>
    </div>
    <InvestmentOverlay ref="PayDialog" :payCard="payCard" :Refresh="Refresh" />
    <Invite ref="inviteRef" />
    <Tabbar />
  </div>
</template>
<script setup>
import { onMounted, reactive, ref } from 'vue';
import router from '@/router';
import Tabbar from '@/components/Tabbar/tabbar.vue';
import {
  invest,
  invest_myjoin,
  shouyi,
  in_wallet,
  personal,
  refresh,
} from '@/api/fetcher';
import { updateCountdown } from '@/utils/endCountdown';
import InvestmentOverlay from '@/components/InvestmentOverlay/index.vue';
import Invite from '@/components/Invite/index.vue';
import Top from '@/components/Top/top.vue';
import message from '@/utils/message';
import { lang } from '@/lang';
import dataCenter from '@/dataCenter';
// 翻译相关
const name5 = lang.v.name5;
const name6 = lang.v.name6;
const warning2 = lang.v.warning2;

// 邀请码相关
const inviteRef = ref(null);

// 据点支付弹窗
const PayDialog = ref(null);

const goldId = ref('1');
const activename = ref('');
const more = ref(false);
const activename_mine = ref('');
const drawing = ref(false);
const Onwithdrawal = async () => {
  drawing.value = true;
  try {
    await in_wallet({
      amount: userinfo.value.investmentBalance,
      type: 2,
    }).then(async () => {
      const userData = await personal().then(res => res);
      dataCenter.user.save(userData);
      userinfo.value = userData;
      message.success(lang.v.success2);
    });
  } catch {
    console.log(1);
  }
  drawing.value = false;
};
const types = [
  {
    id: 1,
    name: name5,
  },
  {
    id: 2,
    name: name6,
  },
];
// 切换Tab栏
const checkTab = async item => {
  pages.page = 1;
  goldId.value = item.id;
  if (goldId.value == 1) {
    activename.value = '1';
    await invest(pages).then(res => {
      projects.value = res;
    });
  }
  if (goldId.value == 2) {
    activename_mine.value = '1';
    if (!window.localStorage.getItem('access_token')) {
      refresh({
        refreshToken: window.localStorage.getItem('refresh_Token'),
      })
        .then(async res => {
          window.localStorage.setItem('access_token', res.access_token); //刷新token
        })
        .catch(() => {
          message.warning(warning2);
          router.push('/login');
        });
    } else {
      await invest_myjoin(pages).then(res => {
        projects.value = res;
      });
      await personal().then(res => {
        dataCenter.user.data = res;
      });
      try {
        const userInfoString = dataCenter.user.data;
        if (userInfoString && userInfoString !== 'undefined') {
          userinfo.value = userInfoString;
        }
      } catch (e) {
        console.error('Error parsing userInfo from localStorage:', e);
      }
    }
  }
};
// 剧集信息
const payCard = ref();

// 购买判断
const isshow = e => {
  if (inviteRef.value.InvitationCode()) {
    inviteRef.value.openInvitaCode();
    return;
  }
  payCard.value = e;
  if (activename.value == 1) {
    PayDialog.value.open();
  }
};

const projects = ref([]);
const loading = ref(false);
//加载更多
const loadmore = async () => {
  loading.value = true;
  setTimeout(async () => {
    if (goldId.value == 1) {
      if (activename.value == 1) {
        await invest({
          status: 1,
          page: pages.page + 1,
          limit: 6,
        }).then(res => {
          loading.value = false;
          const data = res;
          if (data.list.length >= pages.limit) {
            more.value = true;
            pages.page++;
          } else {
            more.value = false;
          }
          const temp = [];
          data.list.forEach(v => {
            temp.push(v);
          });
          projects.value.list = [...projects.value.list, ...temp];
          projects.value.list = [...projects.value.list];
        });
      }
      if (activename.value == 2) {
        await invest({
          status: 2,
          page: pages.page + 1,
          limit: 6,
        }).then(res => {
          const data = res;
          if (data.list.length >= pages.limit) {
            more.value = true;
            pages.page++;
          } else {
            more.value = false;
          }
          const temp = [];
          data.list.forEach(v => {
            temp.push(v);
          });
          projects.value.list = [...projects.value.list, ...temp];
          projects.value.list = [...projects.value.list];
        });
      }
    }
    if (goldId.value == 2) {
      if (activename.value == 1) {
        await shouyi({
          page: pages.page + 1,
          limit: 6,
        }).then(res => {
          loading.value = false;
          const data = res;
          if (data.list.length >= pages.limit) {
            more.value = true;
            pages.page++;
          } else {
            more.value = false;
          }
          const temp = [];
          data.list.forEach(v => {
            temp.push(v);
          });
          projects.value.list = [...projects.value.list, ...temp];
          projects.value.list = [...projects.value.list];
        });
      }
      if (activename.value == 2) {
        await shouyi({
          page: pages.page + 1,
          limit: 6,
        }).then(res => {
          const data = res;
          if (data.list.length >= pages.limit) {
            more.value = true;
            pages.page++;
          } else {
            more.value = false;
          }
          const temp = [];
          data.list.forEach(v => {
            temp.push(v);
          });
          projects.value.list = [...projects.value.list, ...temp];
          projects.value.list = [...projects.value.list];
          console.log(projects.value);
        });
      }
    }
    loading.value = false;
  }, 500);
};
//默认pages
const pages = reactive({
  status: 1,
  page: 1,
  limit: 6,
});
// 切换列表
const changes_isjoin = async name => {
  activename.value = name;
  if (goldId.value == 1) {
    if (activename.value == 1) {
      await invest({
        status: 1,
        page: 1,
        limit: 6,
      }).then(res => {
        projects.value = res;
        if (projects.value.total >= pages.limit) {
          more.value = true;
        } else {
          more.value = false;
        }
      });
    }
    if (activename.value == 2) {
      await invest({
        status: 2,
        page: 1,
        limit: 6,
      }).then(res => {
        projects.value = res;
        if (projects.value.total >= pages.limit) {
          more.value = true;
        } else {
          more.value = false;
        }
      });
    }
  }
  if (goldId.value == 2) {
    if (!window.localStorage.getItem('access_token')) {
      refresh({
        refreshToken: window.localStorage.getItem('refresh_Token'),
      })
        .then(async res => {
          window.localStorage.setItem('access_token', res.access_token); //刷新token
        })
        .catch(() => {
          message.warning(warning2);
          router.push('/login');
        });
    } else {
      if (activename_mine.value == 1) {
        await invest_myjoin({
          page: 1,
          limit: 6,
        }).then(res => {
          projects.value = res;
          if (projects.value.total >= pages.limit) {
            more.value = true;
          } else {
            more.value = false;
          }
        });
      }
      if (activename_mine.value == 2) {
        await shouyi({
          page: 1,
          limit: 6,
        }).then(res => {
          projects.value = res;
          if (projects.value.total >= pages.limit) {
            more.value = true;
          } else {
            more.value = false;
          }
        });
      }
    }
  }
};
const userinfo = ref(dataCenter.user.data);
// 刷新
const Refresh = async () => {
  await invest(pages).then(res => {
    projects.value = res;
    if (projects.value.total >= pages.limit) {
      more.value = true;
    } else {
      more.value = false;
    }
  });
};
// 挂载
onMounted(async () => {
  await invest(pages).then(res => {
    projects.value = res;
    if (projects.value.total >= pages.limit) {
      more.value = true;
    } else {
      more.value = false;
    }
  });
  if (!dataCenter.user.data) {
    await personal().then(res => {
      dataCenter.user.data = res;
    });
  }
});
</script>
<style lang="less" scoped>
.copyRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: #f6f6f8;
  overflow-y: scroll;
}
:deep .van-tabs__line {
  width: 0.3rem;
  height: 0.02rem;
  background: #ffbd00;
  border-radius: 0.1rem;
  opacity: 1;
}

:deep(.el-input-number) {
  width: 100%;
  height: 0.46rem;
  border-radius: 0.06rem;

  .el-input-number__decrease {
    display: none;
  }

  .el-input-number__increase {
    display: none;
  }

  .el-input {
    border: none !important;
    box-shadow: none !important;
    width: 100%;
    height: 0.46rem;

    .el-input__wrapper {
      padding: 0rem;
      width: 100%;
      height: 0.46rem;
      display: flex;
      justify-content: left;
      border: none !important;
      background-color: #f6f6f8;
      box-shadow: none !important;

      .el-input__inner {
        display: flex;
        border: none !important;
        box-shadow: none !important;
        justify-content: left;
        text-align: left;
        padding-left: 0.1rem;
      }
    }
  }
}

:deep(.van-tabs__wrap) {
  height: 0.3rem;

  .van-tabs__nav {
    padding: 0;

    .van-tab {
      padding: 0;
      width: 0.7rem;

      .van-tab__text {
        height: 0.25rem;
        line-height: 0.25rem;
        font-size: 0.14rem;
        font-weight: 500;
      }
    }
  }

  .van-tabs__line {
    padding: 0;
    width: 0.24rem;
    height: 0.02rem;
    bottom: 0rem;
  }
}

:deep(.van-loading) {
  text-align: center;
}

.main {
  width: 92.53%;
  margin: auto;

  .main_top {
    width: 100%;
    margin-top: 0.2rem;
    margin-bottom: 0.04rem;

    .tab {
      width: 72.62%;
      height: 0.32rem;
      background: rgba(133, 140, 159, 0.06);
      margin: auto;
      border-radius: 0.06rem;
      display: flex;
      justify-content: space-between;

      .ordinary {
        width: 46.71%;
        height: 0.32rem;
        text-align: center;
        line-height: 0.3rem;
        font-size: 0.13rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
      }

      .act-ordinary {
        width: 46.71%;
        height: 0.3rem;
        text-align: center;
        line-height: 0.3rem;
        font-size: 0.13rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #444444;
        background: #ffffff;
        box-shadow: 0rem 0rem 0.08rem 0rem rgba(133, 140, 159, 0.15);
        border-radius: 0.04rem;
      }
    }
  }

  .box {
    width: 72.62%;
    margin: auto;
    margin-top: 0.12rem;
  }

  .fenhong {
    width: 100%;
    height: 1.44rem;
    padding-bottom: 0.16rem;
    margin: auto;
    margin-top: 0.12rem;
    border-radius: 0.06rem;
    background: linear-gradient(180deg, #fff9ec 0%, #ffffff 72%) #ffffff;

    .fenhong_top {
      width: 91.93%;
      height: 0.96rem;
      margin: auto;
      border-bottom: 0.01rem solid rgba(133, 140, 159, 0.2);

      .title_2 {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.16rem;
        padding-top: 0.14rem;
      }

      .fenhong_nav {
        display: flex;
        justify-content: space-between;
        margin-top: 0.12rem;

        .right_fenhong_nav {
          width: 1.5rem;
          height: 0.34rem;
          background: #ed931e;
          border-radius: 0.61rem;
          opacity: 1;
          text-align: center;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 0.34rem;
          margin-top: 0.04rem;
        }
      }
    }

    .fenhong_bottom {
      width: 91.93%;
      margin: auto;
      height: 0.2rem;
      font-size: 0.14rem;
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      color: #858c9f;
      line-height: 0.2rem;
      margin-top: 0.2rem;
    }
  }

  .main_body {
    width: 100%;
    padding-bottom: 0.16rem;
    margin: auto;

    .project {
      width: 100%;
      background: #ffffff;
      border-radius: 0.06rem;
      padding-bottom: 0.16rem;
      opacity: 1;
      margin-top: 0.16rem;

      .project_top {
        width: 100%;
        height: 1.6rem;
        position: relative;
        overflow: hidden;
        border-radius: 0.06rem;

        .image-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          // margin: 0.1rem;
          border-radius: 0.06rem;

          .image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
            border-radius: 0.06rem;

            img {
              width: 100%;
              border-radius: 0.06rem;
            }
          }
        }

        .project_name {
          height: 0.2rem;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #444444;
          line-height: 0.2rem;
          margin-left: 0.14rem;
          padding-top: 0.14rem;
        }
      }

      .name_time {
        position: relative;
        margin-top: -0.25rem;
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.2rem;
      }

      .project_info {
        table {
          margin-top: 0.12rem;
          margin-left: 0.14rem;

          .first_info {
            td:nth-child(1) {
              width: 1rem;
              height: 0.34rem;
              font-size: 0.24rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              line-height: 0.34rem;
              background: linear-gradient(90deg, #f46b45 0%, #eea849 100%);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            td:nth-child(2) {
              width: 0.8rem;
              height: 0.22rem;
              font-size: 0.16rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #444444;
              line-height: 0.22rem;
              padding-top: 0.06rem;
            }

            td:nth-child(3) {
              width: 1.2rem;
              height: 0.2rem;
              font-size: 0.14rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #444444;
              line-height: 0.2rem;
              padding-top: 0.09rem;
              overflow: hidden;

              .text {
                margin: 0;
                width: 1.2rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }

          .second_info {
            height: 0.17rem;
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #858c9f;
            line-height: 0.17rem;
          }
        }
      }

      .tip {
        height: 0.17rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.14rem;
        padding-left: 0.14rem;
        padding-right: 0.14rem;
        padding-top: 0.12rem;
      }

      .project_foot {
        display: flex;
        justify-content: space-between;
        margin-top: 0.16rem;

        :deep(.van-progress) {
          .van-progress__portion {
            background: linear-gradient(to right, #f46b45, #eea849) !important;
          }
        }

        .project_schedule {
          margin-left: 0.14rem;

          .schedule {
            width: 200%;
          }

          .src_schedule {
            height: 0.17rem;
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #ed931e;
            line-height: 0.14rem;
            margin-top: 0.08rem;
          }
        }

        .project_check {
          width: 0.96rem;
          height: 0.34rem;
          background: #ed931e;
          border-radius: 0.61rem;
          opacity: 1;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.34rem;
          text-align: center;
          margin-right: 0.14rem;
        }

        .project_checked {
          width: 0.96rem;
          height: 0.34rem;
          background: #444444;
          border-radius: 0.61rem;
          opacity: 1;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.34rem;
          text-align: center;
          margin-right: 0.14rem;
        }
      }
    }

    .project_no {
      width: 100%;
      height: 3.22rem;
      background: #ffffff;
      border-radius: 0.06rem;
      opacity: 1;
      margin-top: 0.16rem;

      .project_top {
        width: 100%;
        height: 1.6rem;
        position: relative;
        overflow: hidden;
        border-radius: 0.06rem;

        .image-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          // margin: 0.1rem;
          border-radius: 0.06rem;

          .image {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
            border-radius: 0.06rem;

            img {
              width: 100%;
              border-radius: 0.06rem;
            }
          }
        }

        .project_name {
          height: 0.2rem;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 500;
          color: #444444;
          line-height: 0.2rem;
          margin-left: 0.14rem;
          padding-top: 0.14rem;
        }
      }

      .name_time {
        position: relative;
        margin-top: -0.25rem;
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 0.2rem;
      }

      .project_info {
        table {
          margin-top: 0.12rem;
          margin-left: 0.14rem;

          .first_info {
            td:nth-child(1) {
              width: 1rem;
              height: 0.34rem;
              font-size: 0.24rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              line-height: 0.34rem;
              color: #c2c5cf;
            }

            td:nth-child(2) {
              width: 0.8rem;
              height: 0.22rem;
              font-size: 0.16rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #c2c5cf;
              line-height: 0.22rem;
              padding-top: 0.06rem;
            }

            td:nth-child(3) {
              width: 1.2rem;
              height: 0.2rem;
              font-size: 0.14rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #c2c5cf;
              line-height: 0.2rem;
              padding-top: 0.09rem;
              overflow: hidden;

              .text {
                margin: 0;
                width: 1.2rem;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }
          }

          .second_info {
            height: 0.17rem;
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c2c5cf;
            line-height: 0.17rem;
          }
        }
      }

      .tip {
        height: 0.17rem;
        font-size: 0.12rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #c2c5cf;
        line-height: 0.14rem;
        padding-left: 0.14rem;
        padding-top: 0.12rem;
      }

      .project_foot {
        display: flex;
        justify-content: space-between;
        margin-top: 0.16rem;

        :deep(.van-progress) {
          .van-progress__portion {
            background: #c2c5cf !important;
          }
        }

        .project_schedule {
          margin-left: 0.14rem;

          .schedule {
            width: 200%;
          }

          .src_schedule {
            height: 0.17rem;
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c2c5cf;
            line-height: 0.14rem;
            margin-top: 0.08rem;
          }
        }

        .project_check {
          width: 0.96rem;
          height: 0.34rem;
          background: #c2c5cf;
          border-radius: 0.61rem;
          opacity: 1;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.34rem;
          text-align: center;
          margin-right: 0.14rem;
        }

        .project_checked {
          width: 0.96rem;
          height: 0.34rem;
          background: #444444;
          border-radius: 0.61rem;
          opacity: 1;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.34rem;
          text-align: center;
          margin-right: 0.14rem;
        }
      }
    }

    .over {
      width: 100%;
      background: #ffffff;
      border-radius: 0.06rem;
      opacity: 1;
      margin-top: 0.16rem;

      .project_name {
        height: 0.2rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        color: #c2c5cf;
        line-height: 0.2rem;
        margin-left: 0.14rem;
        padding-top: 0.14rem;
      }

      .project_info {
        table {
          margin-top: 0.06rem;
          margin-left: 0.14rem;

          .first_info {
            td:nth-child(1) {
              width: 30%;
              height: 0.34rem;
              font-size: 0.24rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 600;
              line-height: 0.34rem;
              background: #c2c5cf;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }

            td:nth-child(2) {
              width: 30%;
              height: 0.22rem;
              font-size: 0.16rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #c2c5cf;
              line-height: 0.22rem;
              padding-top: 0.06rem;
            }

            td:nth-child(3) {
              width: 40%;
              height: 0.2rem;
              font-size: 0.14rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #c2c5cf;
              line-height: 0.2rem;
              padding-top: 0.09rem;
            }
          }

          .second_info {
            height: 0.17rem;
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c2c5cf;
            line-height: 0.17rem;
          }
        }
      }

      .project_foot {
        display: flex;
        justify-content: space-between;
        margin-top: 0.16rem;

        :deep(.van-progress) {
          .van-progress__portion {
            background: #c2c5cf !important;
          }
        }

        .project_schedule {
          margin-left: 0.14rem;

          .schedule {
            width: 200%;
          }

          .src_schedule {
            height: 0.17rem;
            font-size: 0.12rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #c2c5cf;
            line-height: 0.14rem;
            margin-top: 0.08rem;
          }
        }

        .project_check {
          width: 0.96rem;
          height: 0.34rem;
          background: #c2c5cf;
          border-radius: 0.61rem;
          opacity: 1;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.34rem;
          text-align: center;
          margin-right: 0.14rem;
        }

        .project_checked {
          width: 0.96rem;
          height: 0.34rem;
          background: #444444;
          border-radius: 0.61rem;
          opacity: 1;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 0.34rem;
          text-align: center;
          margin-right: 0.14rem;
        }
      }
    }

    .project_fenhong {
      width: 100%;
      max-height: 1.76rem;
      padding-bottom: 0.16rem;
      margin: auto;
      margin-top: 0.12rem;
      border-radius: 0.06rem;
      background: linear-gradient(180deg, #fff9ec 0%, #ffffff 72%) #ffffff;
      .fenhong_top_2 {
        width: 91.93%;
        height: 0.78rem;
        margin: auto;
        background: linear-gradient(
          180deg,
          rgba(237, 147, 30, 0) 39%,
          rgba(237, 147, 30, 0.06) 100%
        );
        border-bottom: 0.04rem solid rgba(237, 147, 30, 0.75);

        .title_2 {
          height: 0.2rem;
          font-size: 0.14rem;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #858c9f;
          line-height: 0.16rem;
          padding-top: 0.14rem;
          display: flex;
          justify-content: space-between;
        }

        .fenhong_nav {
          display: flex;
          justify-content: space-between;
          margin-top: 0.12rem;

          .fenhong_name {
            height: 0.22rem;
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #444444;
            line-height: 0.22rem;
          }

          .fenhong_time {
            height: 0.2rem;
            font-size: 0.14rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 400;
            color: #444444;
            line-height: 0.2rem;
          }
        }
      }

      .fenhong_bottom {
        width: 100%;
        margin: auto;
        height: 0.8rem;
        font-size: 0.14rem;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #858c9f;
        line-height: 0.2rem;
        margin-top: 0.2rem;
        display: flex;
        .fenhong_box {
          margin-left: 0.24rem;

          .box_top {
            display: flex;

            .stick {
              margin-top: 0.05rem;
              width: 0.02rem;
              height: 0.12rem;
              background: #ff5f5b;
              border-radius: 0.06rem;
              opacity: 1;
            }

            .stick2 {
              margin-top: 0.05rem;
              width: 0.02rem;
              height: 0.12rem;
              background: rgba(255, 91, 91, 0.5);
              border-radius: 0.06rem;
              opacity: 1;
            }

            .stick3 {
              margin-top: 0.05rem;
              width: 0.02rem;
              height: 0.12rem;
              background: rgba(91, 176, 255, 1);
              border-radius: 0.06rem;
              opacity: 1;
            }

            .title {
              font-size: 0.16rem;
              font-family: PingFang SC, PingFang SC;
              font-weight: 500;
              color: #59390f;
              line-height: 0.22rem;
              margin-left: 0.06rem;
            }
          }

          .box_nav {
            height: 0.22rem;
            font-size: 0.16rem;
            font-family: PingFang SC, PingFang SC;
            font-weight: 500;
            color: #444444;
            line-height: 0.22rem;
            margin-top: 0.12rem;
            margin-left: 0.14rem;
          }
        }
      }
    }
  }
}

.main_foot {
  width: 100%;
  background-color: #f6f6f8;

  :deep(.van-pagination) {
    border: none !important;
  }

  :deep(.van-pagination__item) {
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 0.06rem;
    margin: 0.06rem;
    color: rgba(237, 147, 30, 1) !important;

    .van-icon {
      color: #000;
    }
  }

  :deep(.van-pagination__item--active) {
    color: #ffffff !important;
    background-color: rgba(237, 147, 30, 1) !important;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
</style>
